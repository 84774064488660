<template>
  <v-container :style="`width: ${($vuetify.breakpoint.xs || $vuetify.breakpoint.sm) ? '' : '1200px !important'};`">
    <v-row>
      <!-- Left -->
      <v-col cols="12" md="5">
        <v-row class="mt-4">
          <v-col cols="12" style="width: 100%;">
            <div style="width: 100%;">
              <!-- Main image -->
              <img :src="imageSelected" width="100%" />
              <!-- Sub image -->
              <div
                style="display: flex; flex: 1; overflow-x: auto; overflow-y: hidden;"
              > 
                <div style="display: flex; justify-content: space-between;">
                  <div v-for="(image, index) in albumImages" :key="image + index" style="flex: 1; width: 253px; height: 168px; padding: 5px;">
                    <img :src="image" width="100%" @click="onSelectImage(image)" class="cursor-pointer"/>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <!-- Right -->
      <v-col cols="12" md="7" style="position: relative;">
        <v-row class="mt-4" style="text-align: left;">
          <v-col cols="12">
            <b class="red--text font-small" style="float: right;">{{ $t('product.detail.remainItems', {count: remainQuantity}) }}</b>
            <h1 class="title-product-size font-weight-medium" style="text-align: left;">
              {{ name }}
            </h1>
            <h3 v-if="pricePromo" class="mt-2 red--text">{{ formatMoney(pricePromo) }} VND</h3>
            <h3 class="mt-2 red--text" :style="pricePromo ? 'text-decoration: line-through; font-size: 14px; font-weight: normal;' : ''">{{ formatMoney(price) }} VND</h3>
            <!-- <h4 class="my-2 font-medium">{{ description }}</h4> -->
            <div style="display: flex;">
              <div
                class="cursor-pointer"
                style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                @click="decreaseQuantity()"
              >
                -
              </div>
              <div
                class="cursor-pointer"
                style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
              >
                {{ quantity }}
              </div>
              <div
                class="cursor-pointer"
                style="padding: 5px 15px 5px 15px; border: 1px solid #e5e5e5;"
                @click="increaseQuantity()"
              >
                +
              </div>
              <v-btn class="ml-2" depressed color="error" @click="onBuyVoucher">
                {{ $t('product.detail.buy') }}
              </v-btn>
            </div>
            <div style="display: flex">
              <b
                v-if="numberOfUsesVoucherValid !== null"
                :class="[numberOfUsesVoucherValid > 0 ? 'blue--text font-small pt-2' : `red--text font-small pt-2`]">
                {{ $t('cart.productCanBuyTimes', {'0': numberOfUsesVoucherValid}) }}</b>
            </div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- Product info -->
    <v-row>
      <v-col cols="12">
        <h1 class="font-weight-light title-product-size">{{ $t('product.detail.productInfo') }}</h1>
        <div style="min-height: 150px; text-align: left;">
          <span :class="['font-weight-light text-uppercase mr-2 cursor-pointer', tabSelected == tabs.INFO ? 'red--text' : 'bluegrey--text']" @click="onSelectTab(tabs.INFO)">{{ $t('product.detail.info') }}</span>
          <span class="mx-4">|</span>
          <span :class="['font-weight-light text-uppercase cursor-pointer', tabSelected == tabs.CONDITIONS_OF_USE ? 'red--text' : 'bluegrey--text']" @click="onSelectTab(tabs.CONDITIONS_OF_USE)">{{ $t('product.detail.conditionsOfUse') }}</span>
          <span class="mx-4">|</span>
          <span :class="['font-weight-light text-uppercase cursor-pointer', tabSelected == tabs.ADDRESS ? 'red--text' : 'bluegrey--text']" @click="onSelectTab(tabs.ADDRESS)">{{ $t('product.detail.locationOfUse') }}</span>
          <span class="mx-4">|</span>
          <span :class="['font-weight-light text-uppercase cursor-pointer', tabSelected == tabs.EXTRA_INFO ? 'red--text' : 'bluegrey--text']" @click="onSelectTab(tabs.EXTRA_INFO)">{{ $t('product.detail.extraInfo') }}</span>
          <p class="font-medium mt-2 pre-text" v-if="tabSelected == tabs.INFO">{{ description }}</p>
          <p class="font-medium mt-2 pre-text" v-else-if="tabSelected == tabs.CONDITIONS_OF_USE">{{ conditionsOfUse }}</p>
          <p class="font-medium mt-2 pre-text" v-else-if="tabSelected == tabs.EXTRA_INFO">{{ details }}</p>
          <div class="font-medium mt-2" v-else v-for="(address, index) in usageSites" :key="address + index">
            <span class="d-block font-weight-bold">{{ index + 1 + '. ' + address.name }}</span>
            <div v-if="address.fullAddress" class="d-flex">
              <v-icon class="mx-2" small>mdi-map-marker</v-icon>
              <span v-if="address.latitude && address.longitude">
                <a :href="`https://www.google.com/maps/place/${address.latitude},${address.longitude}/@${address.latitude},${address.longitude},19z`" target="_blank">{{ address.fullAddress }}</a>
              </span>
              <span v-else>{{ address.fullAddress }}</span>
            </div>
            <div v-if="address.email" class="d-flex">
              <v-icon class="mx-2" small>mdi-email</v-icon>
              <a :href="`mailto:${address.email}`">{{ address.email }}</a>
            </div>
            <div v-if="address.phone" class="d-flex">
              <v-icon class="mx-2" small>mdi-phone</v-icon>
              <a :href="`tel:${address.phone}`">{{ address.phone }}</a>
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import functionUtils from 'Utils/functionUtils'
import StoreChannelType from 'Enum/storeChannelType'

export default {
  data: () => ({
    cartList: [],
    name: null,
    price: null,
    pricePromo: null,
    quantity: 1,
    remainQuantity: 0,
    isShowDescription: true,
    conditionsOfUse: null,
    details: null,
    description: null,
    imageUrl: null,
    id: null,
    albumImages: [],
    imageSelected: null,
    code: null,
    indexRetrievingAddessUsage: 0,
    usageSites: [],
    tabs: {
      INFO: 1,
      CONDITIONS_OF_USE: 2,
      ADDRESS: 3,
      EXTRA_INFO: 4
    },
    tabSelected: 1,
    issuanceId: null,
    numberOfUsesVoucherValid: 0
  }),
  watch: {
    CARTS_DATA() {
      this.cartList = this.CARTS_DATA;
    }
  },
  created () {
    let productId = parseInt(this.$route.params.id)

    this.cartList = this.CARTS_DATA;
    let cartList = JSON.parse(sessionStorage.getItem('carts'))
    if (cartList && this.cartList.length === 0 && cartList.length !== 0) {
      this.cartList = cartList
    }
    for (let i = 0, size = this.cartList.length; i < size; i++) {
      if (this.cartList[i].id === productId) {
        this.quantity = this.cartList[i].quantity
      }
    }
    let queryRouter = this.$route.query
    this.issuanceId = queryRouter && queryRouter.issuanceId

    let filter = {
      params: {
        issuanceId: this.issuanceId
      }
    }
    this.GET_PRODUCT_DETAIL({ id: productId, ...filter }).then(
      function (res) {
        this.albumImages = []
        let data = res.data
        this.name = data.name
        this.price = data.value
        this.pricePromo = data.extra_info.product ? data.extra_info.product.price_promo : null
        this.imageUrl = data.image_url
        this.id = data.id
        this.code = data.code
        this.remainQuantity = data.warehouse
        // Handle get images
        let supplierImages = data.extra_info.supplier ? data.extra_info.supplier.album_image_urls : null
        let agentImage = data.extra_info.agent ? data.extra_info.agent.image_url : null
        let agentAlbumImages = data.extra_info.agent ? data.extra_info.agent.album_image_urls : null
        if (this.imageUrl) {
          this.albumImages.push(this.imageUrl)
        }
        if (supplierImages) {
          this.albumImages.push(...supplierImages)
        }
        if (agentImage) {
          this.albumImages.push(agentImage)
        }
        if (agentAlbumImages) {
          this.albumImages.push(...agentAlbumImages)
        }
        if (this.albumImages.length > 0) {
          this.imageSelected = this.albumImages[0]
        }
        this.conditionsOfUse = data.conditions_of_use
        this.details = data.details
        this.description = data.description
        this.usageSites = []
        data.usage_sites.forEach((site) => {
          let item = {
            id: site.id,
            name: site.name,
            code: site.code,
            longitude: site.longitude,
            latitude: site.latitude,
            province: site.province,
            district: site.district,
            ward: site.ward,
            street: site.street,
            fullAddress: null
          }
          this.usageSites.push(item)
        })
        this.indexRetrievingAddessUsage = 0;
        this.numberOfUsesVoucherValid = data.number_of_uses_voucher_valid
        this.doRetrievingFullAddressUsage()
      }.bind(this)
    )
  },
  computed: {
    ...mapGetters(["CARTS_DATA"]),
  },
  methods: {
    /**
     * Select tab
     */
    onSelectTab: function (tab) {
      this.tabSelected = tab
    },
    /**
     * Select image
     */
    onSelectImage: function (image) {
      this.imageSelected = image
    },
    /**
     * Format money
     */
    formatMoney: function (price) {
      if (price) {
        return functionUtils.convertFormatNumberToMoney(price.toString())
      } else {
        return 0
      }
    },
    isNotNull: function(value) {
      return !functionUtils.isNull(value)
    },
    /**
     * Buy voucher
     */
    onBuyVoucher: function () {
      if (this.isNotNull(this.numberOfUsesVoucherValid) && this.numberOfUsesVoucherValid < this.quantity) {
        this.$toast.open({
          message: this.$t('cart.productCanBuyTimes', {'0': this.numberOfUsesVoucherValid}),
          type: "error",
          ...this.$toastConfig,
        });
        return
      }
      if (this.quantity < 1) {
        this.$toast.open({
          message: this.$t("cart.quantityMustGreatThanZero"),
          type: "error",
          ...this.$toastConfig,
        });
        return
      }
      if (this.quantity > this.remainQuantity) {
        this.$toast.open({
          message: this.$t("cart.addToCartOverQuantity"),
          type: "error",
          ...this.$toastConfig,
        });
        return
      }
      // let productInfo = {
      //   id: this.id,
      //   image: this.imageUrl,
      //   quantity: this.quantity,
      //   remainQuantity: this.remainQuantity,
      //   price: this.pricePromo ? this.pricePromo : this.price,
      //   name: this.name,
      //   code: this.code
      // }
      // for (let i = 0, size = this.cartList.length; i < size; i++) {
      //   if (this.cartList[i].id == this.id) {
      //     if (this.quantity > this.remainQuantity) {
      //       this.$toast.open({
      //         message: this.$t("cart.addToCartOverQuantity"),
      //         type: "error",
      //         ...this.$toastConfig,
      //       });
      //     } else {
      //       this.cartList[i].quantity = this.quantity
      //       this.UPDATE_PRODUCT_QUANTITY(this.cartList[i])
      //       this.$toast.open({
      //         message: this.$t("cart.addToCartSuccessed"),
      //         type: "success",
      //         ...this.$toastConfig,
      //       });
      //     }
      //     return
      //   }
      // }
      let productInfo = {
        workpieceId: this.id,
        quantity: this.quantity,
        storeChannelId: StoreChannelType.PRODUCT_PORTAL,
        issuanceId: this.issuanceId
      }
      this.ADD_VOUCHER_ORDER_CART(productInfo).then(
        function () {
          this.getVoucherOrderCart()
          this.$toast.open({
            message: this.$t("cart.addToCartSuccessed"),
            type: "success",
            ...this.$toastConfig,
          });
        }.bind(this)
      )
    },
    doRetrievingFullAddressUsage: function () {
      if (this.usageSites && this.indexRetrievingAddessUsage < this.usageSites.length) {
        let addressFilter = {
          params: {
            countryCode: 84,
            cityCode: this.usageSites[this.indexRetrievingAddessUsage].province,
            districtCode: this.usageSites[this.indexRetrievingAddessUsage].district,
            wardCode: this.usageSites[this.indexRetrievingAddessUsage].ward
          }
        }
        this.GET_ADDRESS_DETAIL(addressFilter).then(
          function (res) {
            let fullAddress = functionUtils.concatFullAddress(res.data, this.usageSites[this.indexRetrievingAddessUsage].street)
            this.usageSites[this.indexRetrievingAddessUsage].fullAddress = fullAddress
            // get update next item if any
            this.indexRetrievingAddessUsage++
            this.doRetrievingFullAddressUsage()
          }.bind(this)
        )
      }
    },
    /**
     * Increase quantity
     */
    increaseQuantity: function () {
      if (this.numberOfUsesVoucherValid !== null && this.numberOfUsesVoucherValid < this.quantity + 1) {
        this.$toast.open({
          message: this.$t('cart.productCanBuyTimes', {'0': this.numberOfUsesVoucherValid}),
          type: "error",
          ...this.$toastConfig,
        });
      } else if (this.quantity + 1 <= this.remainQuantity) {
        this.quantity = this.quantity + 1
      }
    },
    decreaseQuantity: function () {
      if (this.quantity > 0) {
        this.quantity = this.quantity - 1
      }
    },
    getVoucherOrderCart: function () {
      let filter = {
        params: {
          storeChannelId: StoreChannelType.PRODUCT_PORTAL
        }
      }
      this.GET_VOUCHER_ORDER_CART_LIST(filter).then(
        function () {}.bind()
      )
    },
    ...mapActions(['GET_PRODUCT_DETAIL', 'ADD_VOUCHER_ORDER_CART', 'GET_ADDRESS_DETAIL', 'UPDATE_PRODUCT_QUANTITY', 'GET_VOUCHER_ORDER_CART_LIST'])
  }
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer
}
</style>
